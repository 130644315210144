import React from 'react'

import { Col, Row, Section } from '../components/grid'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import { H1, H4 } from 'components/typography/Headings'

export default function PrivacyPolicyPage() {
	return (
		<Layout pageName="Terms of Use">
			<Section margin="mt-20">
				<Row center>
					<div className="prose prose-lg prose-gray px-4 sm:px-0">
						<H1>americanreading.com Terms of Use</H1>
						<span className="font-semibold">Last Updated: June 23, 2023</span>
						<p>
							Welcome to americanreading.com, the corporate website (site) of American Reading Company (ARC). These Terms of Use govern your use of our website
							(americanreading.com) and its services. By accessing or using americanreading.com, you agree to be bound by these terms. If you do not agree with any
							part of these terms, please do not use our website or services.
						</p>
						<H4>1. Acceptance of Terms</H4>
						<p>
							These Terms of Use constitute a legally binding agreement between you and americanreading.com. You must accept these terms in their entirety to use our
							website and services. Your use of americanreading.com indicates your acceptance of these terms.
						</p>
						<H4>2. Intellectual Property</H4>
						<p>
							All content on americanreading.com, including but not limited to text, graphics, logos, images, videos, and software, is the property of ARC or its
							content suppliers. This content is protected by intellectual property laws, including copyright, trademark, and other proprietary rights. You may not
							modify, reproduce, distribute, display, or exploit any part of our website or its content without prior written permission from ARC.
						</p>
						<H4>3. Use of Products & Services</H4>
						<p>
							Americanreading.com provides various products and services, including but not limited to curated libraries, instructional materials, articles,
							professional development, and educational resources. You may use these services in accordance with these terms. You agree not to use our services for
							any illegal or unauthorized purpose or in violation of any applicable laws or regulations.
						</p>
						<p>By using or purchasing any products from this website, you agree to be bound by the following:</p>
						<ol type="a">
							<li>
								Product Information: americanreading.com provides a platform for the sale of educational and literacy-related products. We strive to present
								accurate and up-to-date information about our products, including descriptions, prices, and availability. However, we do not guarantee the accuracy,
								completeness, or timeliness of the information provided. We reserve the right to modify or discontinue any product at any time without prior notice.
							</li>
							<li>
								Ordering and Payment: When you place an order for a product on americanreading.com, you are making an offer to purchase that product. We reserve the
								right to accept or decline your offer at our discretion. All payments are processed through ARC’s Sales Operations team. Terms and conditions apply.{' '}
								<Link to="/contact-us" className="text-ab-100">
									Contact your Account Representative
								</Link>{' '}
								for details. ARC Professional Development cannot be purchased on the website. Please{' '}
								<Link to="/contact-us" className="text-ab-100">
									contact your ARC Account Representative
								</Link>{' '}
								for details.
							</li>
							<li>
								Shipping and Delivery: ARC will make reasonable efforts to deliver the purchased products to the shipping address you provide. However, we do not
								guarantee the availability of all products for immediate delivery. Shipping times and costs may vary based on your location and the chosen shipping
								method. Any estimated delivery dates provided are approximate. ARC is not responsible for any delays or errors caused by third-party shipping
								providers. Unless otherwise agreed to by the parties, physical Products will be shipped free on board (FOB) origin. ARC shall deliver physical
								Products using ARC’s standard methods for packaging and shipping such Products. Customer shall accept and take delivery of the physical Products
								within fifteen (15) business days of delivery. Upon acceptance of the Products, orders are non-refundable, non-returnable, and non-exchangeable,
								except for damaged or defective materials, materials shipped in error, or materials subject to ARC’s Return Policy.
							</li>
							<li>Exchanges: Requests for exchanges are subject to our Exchange Policy.</li>
							<li>
								Disclaimer of Warranties: ARC provides its products on an "as is" and "as available" basis. We do not make any warranties or representations
								regarding the suitability, reliability, or accuracy of the products or the website content. We disclaim all warranties, whether express or implied,
								including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. ARC is neither responsible nor
								liable for any third-party content included in products, including the accuracy, integrity, completeness, quality, legality, usefulness of, or IP
								rights relating to, such third-party content.
							</li>
						</ol>
						<H4>4. User Conduct</H4>
						<p>When using americanreading.com, you agree to abide by the following rules:</p>
						<ol type="a">
							<li>You will not engage in any activity that is harmful, offensive, or disruptive to other users or our website.</li>
							<li>You will not upload, post, or transmit any content that is unlawful, abusive, defamatory, or infringes upon the rights of others.</li>
							<li>You will not attempt to gain unauthorized access to any part of americanreading.com or its services.</li>
							<li>You will not interfere with the proper functioning of our website or services.</li>
							<li>You will not impersonate any person or entity or falsely represent your affiliation with any person or entity.</li>
						</ol>
						<H4>5. Privacy Policy</H4>
						<p>
							Please review our Privacy Policy{' '}
							<Link to="/privacy-policy" className="text-ab-100">
								here
							</Link>
							.
						</p>
						<H4>6. Third-Party Websites and Content</H4>
						<p>
							Americanreading.com may contain links to third-party websites or display content from third-party sources. These links and content are provided for your
							convenience, and we do not endorse or assume any responsibility for the content, products, or services offered by third parties. You acknowledge and
							agree that your use of third-party websites or content is at your own risk.
						</p>
						<H4>7. Limitation of Liability</H4>
						<p>
							In no event shall ARC, americanreading.com, or its affiliates, directors, employees, or agents be liable for any direct, indirect, incidental, special,
							or consequential damages arising out of or in connection with your use of our website or services, even if advised of the possibility of such damages.
						</p>
						<H4>8. Indemnification</H4>
						<p>
							You agree to indemnify and hold americanreading.com and its affiliates, directors, employees, or agents harmless from any claims, losses, damages,
							liabilities, costs, or expenses arising out of or in connection with your use of our website or services, your violation of these terms, or your
							infringement of any intellectual property or other rights of any third party.
						</p>
						<H4>9. Modification and Termination</H4>
						<p>
							ARC reserves the right to modify or terminate these Terms of Use or any part of our website or services at any time without prior notice. It is your
							responsibility to review these terms periodically for any changes. Your continued use of our website or services after any modifications indicates your
							acceptance of the modified terms.
						</p>
						<H4>10. Governing Law and Jurisdiction</H4>
						<p>
							These Terms shall be governed by and construed in accordance with the laws of the United States of America, without regard to its conflict of law
							principles. Any dispute arising out of or relating to these Terms or the Website shall be resolved exclusively in the state or federal courts located in
							the jurisdiction where americanreading.com is located (Pennsylvania).
						</p>
					</div>
				</Row>
			</Section>
		</Layout>
	)
}
